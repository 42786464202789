import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
  MessageParagraph,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Charles's Law Calculator",
    link: "/charles-law-calculator/",
  },
]
const seeAlsoArray = [
  "/boyles-law-calculator",
  "/ohms-law-calculator",
  "/specific-heat-calculator",
  "/percentage-calculator",
]

function CharlessLawCalculator(props) {
  let volume1 = useRef("")
  let volume1Unit = useRef("")
  let temperature1 = useRef("")
  let temperature1Unit = useRef("")
  let volume2 = useRef("")
  let volume2Unit = useRef("")
  let temperature2 = useRef("")
  let temperature2Unit = useRef("")

  let [MyError, setMyError] = useState("")

  // var mydiv = document.getElementById('myerror');
  var reset = function () {
    volume1.current.value = ""
    temperature1.current.value = ""
    volume2.current.value = ""
    temperature2.current.value = ""
    setMyError("")
  }

  var print = function (variable, answer) {
    if (variable === "volume1") {
      volume1.current.value = answer
    } else if (variable === "temperature1") {
      temperature1.current.value = answer
    } else if (variable === "volume2") {
      volume2.current.value = answer
    } else {
      temperature2.current.value = answer
    }
  }

  var convertToLiters = function (unit, volume) {
    if (unit === "Milliliters") {
      return volume / 1000
    } else {
      return volume
    }
  }

  var convertToMilliliters = function (unit, volume) {
    if (unit === "Liters") {
      return volume * 1000
    } else {
      return volume
    }
  }

  var convertToKelvin = function (unit, temperature) {
    if (unit === "Celsius") {
      return temperature + 273
    } else if (unit === "Fahrenheit") {
      return (temperature - 32) / 1.8 + 273
    } else {
      return temperature
    }
  }

  var convertToCelsius = function (unit, temperature) {
    if (unit === "Kelvin") {
      return temperature - 273
    } else if (unit === "Fahrenheit") {
      return (temperature - 32) / 1.8
    } else {
      return temperature
    }
  }

  var convertToFahrenheit = function (unit, temperature) {
    if (unit === "Kelvin") {
      return 1.8 * (temperature - 273) - 32
    } else if (unit === "Celsius") {
      return 1.8 * temperature - 32
    } else {
      return temperature
    }
  }

  var convertAnswer = function (
    variable,
    volume1Unit,
    volume2Unit,
    temperature1Unit,
    temperature2Unit,
    convertedTemperaturesUnit,
    answer
  ) {
    if (variable === "volume1" && volume1Unit === "Liters") {
      return convertToLiters("Liters", answer)
    } else if (variable === "volume1" && volume1Unit === "Milliliters") {
      return convertToMilliliters("Liters", answer)
    } else if (variable === "volume2" && volume2Unit === "Liters") {
      return convertToLiters("Liters", answer)
    } else if (variable === "volume2" && volume2Unit === "Milliliters") {
      return convertToMilliliters("Liters", answer)
    } else if (variable === "temperature1" && temperature1Unit === "Kelvin") {
      return convertToKelvin(convertedTemperaturesUnit, answer)
    } else if (variable === "temperature1" && temperature1Unit === "Celsius") {
      return convertToCelsius(convertedTemperaturesUnit, answer)
    } else if (
      variable === "temperature1" &&
      temperature1Unit === "Fahrenheit"
    ) {
      return convertToFahrenheit(convertedTemperaturesUnit, answer)
    } else if (variable === "temperature2" && temperature2Unit === "Kelvin") {
      return convertToKelvin(convertedTemperaturesUnit, answer)
    } else if (variable === "temperature2" && temperature2Unit === "Celsius") {
      return convertToCelsius(convertedTemperaturesUnit, answer)
    } else if (
      variable === "temperature2" &&
      temperature2Unit === "Fahrenheit"
    ) {
      return convertToFahrenheit(convertedTemperaturesUnit, answer)
    }
  }

  var calculation = function (
    variable,
    volume1,
    temperature1,
    volume2,
    temperature2
  ) {
    if (variable === "volume1") {
      return (volume2 * temperature1) / temperature2
    } else if (variable === "temperature1") {
      return (volume1 * temperature2) / volume2
    } else if (variable === "volume2") {
      return (volume1 * temperature2) / temperature1
    } else {
      return (volume2 * temperature1) / volume1
    }
  }

  let inputIsEmpty =
    "Please input three out of four numbers. We can't do chemistry without any numbers!"

  var errors = function (volume1, temperature1, volume2, temperature2) {
    if (
      volume1 === "" &&
      temperature1 === "" &&
      volume2 === "" &&
      temperature2 === ""
    ) {
      setMyError(inputIsEmpty)
    } else if (
      volume1 !== "" &&
      temperature1 !== "" &&
      volume2 !== "" &&
      temperature2 !== ""
    ) {
      setMyError(
        "Please only input three out of four numbers. We can't do any chemistry if you already have all the numbers!"
      )
    } else if (volume1 === "" && temperature1 === "" && volume2 === "") {
      setMyError(
        "Please input three out of four numbers. We can't do chemistry without any numbers!"
      )
    } else if (volume1 === "" && temperature1 === "" && temperature2 === "") {
      setMyError(inputIsEmpty)
    } else if (volume1 === "" && volume2 === "" && temperature2 === "") {
      setMyError(inputIsEmpty)
    } else if (temperature1 === "" && volume2 === "" && temperature2 === "") {
      setMyError(inputIsEmpty)
    } else if (volume1 === "" && temperature1 === "") {
      setMyError(inputIsEmpty)
    } else if (volume1 === "" && volume2 === "") {
      setMyError(inputIsEmpty)
    } else if (volume1 === "" && temperature2 === "") {
      setMyError(inputIsEmpty)
    } else if (temperature1 === "" && volume2 === "") {
      setMyError(inputIsEmpty)
    } else if (temperature1 === "" && temperature2 === "") {
      setMyError(inputIsEmpty)
    } else if (volume2 === "" && temperature2 === "") {
      setMyError(inputIsEmpty)
    } else if (volume1 < 0 || volume2 < 0) {
      setMyError(
        "You can't have any negative numbers. It's impossible to have a negative volume!"
      )
    } else {
      setMyError("")
      return true
    }
  }

  var findVariable = function (volume1, temperature1, volume2, temperature2) {
    if (volume1 === "") {
      return "volume1"
    } else if (temperature1 === "") {
      return "temperature1"
    } else if (volume2 === "") {
      return "volume2"
    } else {
      return "temperature2"
    }
  }

  function calculate() {
    let Xvolume1 = volume1.current.value
    let Xtemperature1 = temperature1.current.value
    let Xvolume2 = volume2.current.value
    let Xtemperature2 = temperature2.current.value

    if (Xvolume1 !== "") {
      Xvolume1 = Number(Xvolume1)
    }
    if (Xtemperature1 !== "") {
      Xtemperature1 = Number(Xtemperature1)
    }
    if (Xvolume2 !== "") {
      Xvolume2 = Number(Xvolume2)
    }
    if (Xtemperature2 !== "") {
      Xtemperature2 = Number(Xtemperature2)
    }

    let Xvolume1Unit = volume1Unit.current.value
    let Xtemperature1Unit = temperature1Unit.current.value
    let Xvolume2Unit = volume2Unit.current.value
    let Xtemperature2Unit = temperature2Unit.current.value

    var convertedTemperaturesUnit

    var variable = findVariable(
      Xvolume1,
      Xtemperature1,
      Xvolume2,
      Xtemperature2
    )

    if (errors(Xvolume1, Xtemperature1, Xvolume2, Xtemperature2)) {
      Xvolume1 = convertToLiters(Xvolume1Unit, Xvolume1)
      Xvolume2 = convertToLiters(Xvolume2Unit, Xvolume2)

      if (convertToKelvin(Xtemperature1Unit, Xtemperature1) === 0) {
        if (convertToCelsius(Xtemperature2Unit, Xtemperature2) === 0) {
          Xtemperature1 = convertToFahrenheit(Xtemperature1Unit, Xtemperature1)
          Xtemperature2 = convertToFahrenheit(Xtemperature2Unit, Xtemperature2)
          convertedTemperaturesUnit = "Fahrenheit"
        } else {
          Xtemperature1 = convertToCelsius(Xtemperature1Unit, Xtemperature1)
          Xtemperature2 = convertToCelsius(Xtemperature2Unit, Xtemperature2)
          convertedTemperaturesUnit = "Celsius"
        }
      } else if (convertToKelvin(Xtemperature2Unit, Xtemperature2) === 0) {
        if (convertToCelsius(Xtemperature1Unit, Xtemperature1) === 0) {
          Xtemperature1 = convertToFahrenheit(Xtemperature1Unit, Xtemperature1)
          Xtemperature2 = convertToFahrenheit(Xtemperature2Unit, Xtemperature2)
          convertedTemperaturesUnit = "Fahrenheit"
        } else {
          Xtemperature1 = convertToCelsius(Xtemperature1Unit, Xtemperature1)
          Xtemperature2 = convertToCelsius(Xtemperature2Unit, Xtemperature2)
          convertedTemperaturesUnit = "Celsius"
        }
      } else {
        Xtemperature1 = convertToKelvin(Xtemperature1Unit, Xtemperature1)
        Xtemperature2 = convertToKelvin(Xtemperature2Unit, Xtemperature2)
        convertedTemperaturesUnit = "Kelvin"
      }

      var answer = calculation(
        variable,
        Xvolume1,
        Xtemperature1,
        Xvolume2,
        Xtemperature2
      )
      answer = convertAnswer(
        variable,
        Xvolume1Unit,
        Xvolume2Unit,
        Xtemperature1Unit,
        Xtemperature2Unit,
        convertedTemperaturesUnit,
        answer
      )
      print(variable, answer)
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Charles's Law Calculator"
        description="Charles's law (the law of volumes) is an experimental gas law that describes how gases tend to expand when heated."
        keywords={[
          "Charles's Law, Charles's Law calculator, calculate Charles's Law,physicist charles law, calculate law of volumes, law of volumes calculator, charles law calculator online.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>&#9883; Charles's Law Calculator</Title>
        <SubTitle>Charles's Law also known as the law of volumes</SubTitle>

        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Volume 1</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={volume1} />
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="volume-one"
                    ref={volume1Unit}
                  >
                    <option value="Liters">L</option>
                    <option value="Milliliters">mL</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Temperature 1</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={temperature1} />
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="Temperature-one"
                    ref={temperature1Unit}
                  >
                    <option value="Kelvin">K</option>
                    <option value="Celsius">&#176;C</option>
                    <option value="Fahrenheit">&#176;F</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Volume 2</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={volume2} />
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="volume-two"
                    ref={volume2Unit}
                  >
                    <option value="Liters">L</option>
                    <option value="Milliliters">mL</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Temperature 2</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={temperature2} />
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="Temperature-two"
                    ref={temperature2Unit}
                  >
                    <option value="Kelvin">K</option>
                    <option value="Celsius">&#176;C</option>
                    <option value="Fahrenheit">&#176;F</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <FlexDiv display="flex">
              <Button
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                value="Calculate"
                onClick={calculate}
              >
                Calculate
              </Button>
              <Button
                borderColor="#ea4336"
                color="#ea4336"
                hoverBorderColor="#ea4336"
                hoverBackgroundColor="#ea4336"
                hoverColor="white"
                value="Reset"
                onClick={reset}
              >
                Reset
              </Button>
            </FlexDiv>

            <MessageParagraph color="red">{MyError}</MessageParagraph>
          </Column>
          <Column>
            <FlexDiv maxWidth="400px" margin="auto">
              <SmallImg filename="charles.png" alt="calculate charles law" />
            </FlexDiv>
          </Column>
        </Columns>

        <br />

        <h3>Charles's Law</h3>
        <p>
          Charles's law (the law of volumes) is an experimental gas law that
          describes how gases tend to expand when heated.
          <br />
          the conclusion of Charles's Law that the volume of given mass changes
          with the changing of the temperature of the gas when pressure is kept
          constant.
        </p>

        <h3>Charles's Law Formula</h3>
        <p>
          Charles's Law can be used to compare varying conditions for a gas. Now
          The mathematical relationship of Charles's Law becomes: initial volume
          (V1) and temperature T1 while final volume (V2) and temperature (T2)
          <br />
          <strong>
            V<sub>1</sub>/T<sub>1</sub> = V<sub>2</sub>/T<sub>2</sub>
          </strong>
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default CharlessLawCalculator
